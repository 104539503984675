.my-activity {
  overscroll-behavior: contain;
  // Fix for redundant scroll for bonus history coupons
  display: flex;
  flex-direction: column;
}

.my-account-activity-icon {
  font-size: var(--font-size-16);
  margin-right: var(--size-8);

  &--incoming {
    color: var(--success);
  }

  &--outgoing {
    color: var(--body-40);
  }
}

.my-account-activity {
  &__heading {
    padding: 0 var(--size-16) var(--size-24);

    .heading--3 {
      margin-bottom: var(--size-24);
    }
  }
  &__chips {
    .chip {
      font-size: var(--font-size-14);
      margin-left: .25rem;
      margin-right: .25rem;
      width: 100%;
      background: var(--primary-500);
      color: var(--sub-nav-pills-color);
      border-color: var(--sub-nav-pills-color);
      &.active,
      &:hover {
        background-color: var(--primary-500);
        color: var(--white);
        border-color: var(--chip-rounded-hover-background);
      }
    }
    svg {
      margin-right: var(--size-6);
      height: var(--size-18);
      width: var(--size-18);
    }
    display: flex;
    flex-direction: row;
  }

  &__toggle-btns {
    display: flex;
    justify-content: flex-start;
    row-gap: var(--size-8);
    flex-wrap: wrap;

    .button:not(.button--text):not(.button--text-alt).button--small {
      border-radius: var(--size-18);
      font-size: var(--size-12);
      padding: var(--size-10) var(--size-14);
    }

    button.button.toggle-button--selected {
      background-color: var(--body-35);
      border-color: var(--body-35);
    }

    button.button:not(.toggle-button--selected) {
      background-color: transparent;
      border-color: var(--body-35);
      color: var(--body-35);
    }
  }

  &__content {
    background-color: var(--primary-900);
    border-radius: var(--size-16) var(--size-16) 0 0;
    padding: var(--size-16);
  }

  &__data {
    position: relative;
  }

  &__transactions-wrapper {
    position: relative;

    .tdl {
      padding: 0;
      background-color: var(--primary-900);

      &__image-container {
        width: 50%;
      }

      &__total-results-text {
        color: var(--white);
        margin: auto;
      }
    }
  }

  &__content-download-btn {
    position: absolute;
    display: flex;
    font-family: inherit;
    color: var(--tdl-total-color);
    font-size: var(--size-12);
    top: var(--size-2);
    right: 0;
    cursor: pointer;
    background: none;
    border: none;

    &:focus {
      outline: 2px solid var(--primary-400);
    }
  }

  &__daterange {
    margin-bottom: var(--size-20);

    .input__box-container,
    .my-account-activity__date-range {
      border-radius: var(--size-8);
    }
  }

  &__category-button {
    margin-right: var(--size-8);
  }

  &__activity-body-item-heading {
    display: block;
    color: var(--body-60);
    font-weight: var(--text-highlight-weight-tabular);
  }

  &__activity-body-item-value {
    display: block;
    margin-bottom: var(--size-8);
  }

  &__activity-body {
    font-size: var(--font-size-10);
    line-height: var(--size-14);
    width: 100%;
  }

  &__cancel-button {
    text-align: center;
    margin-top: var(--size-32);

    .button.button--text {
      font-size: var(--font-size-14);
      font-weight: var(--btn-font-weight);
    }
  }

  &__coupon-info {
    margin: var(--size-10) 0;
    display: flex;
    flex-direction: column;
  }

  &__coupon-ref {
    display: block;
    font-size: var(--size-8);
    font-weight: var(--text-highlight-weight-tabular);
  }

  &__coupon-details {
    display: block;
    font-size: var(--size-10);
    font-weight: var(--text-highlight-weight-tabular);
    color: var(--body-90);
    margin-bottom: var(--size-8);
  }

  &__date-range {
    text-transform: capitalize;
  }

  &__limit-warning {
    margin: 2rem 0.2rem;
    color: var(--white);
  }
}
