.prompt {
  &--default {
    .touch-drawer {
      &__content {
        .prompt__image {
          width: 100%;
        }
      }
    }
  }

  &--cancellation {
    .touch-drawer {
      &__footer {
        flex-flow: row nowrap;
      }

      &__header {
        padding-bottom: var(--size-12);
      }
    }
  }
  .label--floating {
    display: none;
  }
  .paragraph {
    &--sm {
      margin-bottom: var(--size-12);
    }
    &--strong {
      font-weight: bold;
    }
  }
  .input__box {
    border-radius: var(--size-16);
    &-container {
      border-radius: var(--size-16);
    }
  }
  .input-validation-alert {
    min-height: var(--size-14);
  }
}
